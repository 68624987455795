<script>

import TransactionServiceApi from "@/service/api/transaction.js";

export default {
  props: ["active", "data"],
  data() {
    return {
      updating:false,
    };
  },

  methods: {
    updateTransaction() {
      this.updating= true;
      const data = {
        transaction_id: this.data.id,
        progress: 2,
        action: "update",
      };
      TransactionServiceApi.updateTransaction(data)
        .then(() => {
           this.ToastMessage({title:"Item Received",body:"Item recieved successfully!"})
            this.$refs["RecieveModal"].hide();
             this.$emit("itemRecieved");
        })
        .catch(() => {
          this.ToastMessage({title:"Item Received",body:"Updation Failed"},"danger")
        }).then(()=>{
              this.updating= false;
        });
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.$refs["RecieveModal"].show();
      }
    },
  },
  computed: {},
};
</script>

<template>
  <b-modal
    ref="RecieveModal"
    title="En-route to sheesh"
    @hidden="
      () => {
        this.$emit('reset-recieve-modal', true);
      }
    "
    hide-footer
  >
    <div v-if="data">
      <div style="text-align:center">
        <div class="ml-5 mr-5">
          <img
            v-if="data.size.product.image"
            :src="data.size.product.image"
            alt=""
            srcset=""
            class="img-fluid"
          />
        </div>
        <div>
          <span class="font-weight-bold"> Size : </span> {{ data.size.size }}
        </div>
        <div>
          <span class="font-weight-bold"> Quantity :</span>
          {{ data.size.quantity }}
        </div>
      </div>
      <p class="my-4 p-2">
        Did you receive the ORDER-{{ data.id }} with the product
        <em>{{ data.size.product.name }}</em> from the seller
        <span v-if="data.seller">{{ data.seller.user.name }}</span>
        <span v-else>{{ data.user.name }}</span>
        for the buyer
        <span v-if="data.buyer">{{ data.buyer.user.name }}</span>
        <span v-else>{{ data.user.name }}</span
        >?
      </p>
      <div>
        <button
          class="btn btn-secondary"
          @click="
            () => {
              this.$refs['RecieveModal'].hide();
            }
          "
        >
          Cancel
        </button>
        <button @click=" updateTransaction" class="btn btn-danger float-right"> <span v-if="updating">Confirming..</span> <span v-else> Confirm</span></button>
      </div>
    </div>
  </b-modal>
</template>
