<script>
import Layout from "../../layouts/main";
import Orders from "@/components/transactions/orders";
import TransactionServiceApi from "@/service/api/transaction";
import QrCodeServiceApi from "@/service/api/qrcode";
import Spinner from "@/components/spinner";
import QRCode from "@/components/qrcode";

import ChangeProgress from "@/components/transactions/change-progress";
export default {
  components: { Layout, Spinner, Orders, ChangeProgress, QRCode },
  data() {
    return {
      orderData: [],
      transactionSelect: null,
      showQR: false,
      rows: null,
      qrData: null,
      qrGenerating: false,
      step: this.$route.params.tab ? this.$route.params.tab : 0,
      order: {
        pendingShipment: {
          perPage: 10,
          page: 1,
        },
        enRouteToSheesh: {
          perPage: 10,
          page: 1,
        },
        AuthProcess: {
          perPage: 10,
          page: 1,
        },
        enRouteToBuyers: {
          perPage: 10,
          page: 1,
        },
        receivedByBuyer: {
          perPage: 10,
          page: 1,
        },
        itemRejected: {
          perPage: 10,
          page: 1,
        },
        itemCancelled: {
          perPage: 10,
          page: 1,
        },
        paymentFailed: {
          perPage: 10,
          page: 1,
        },
      },
      loading: false,

      perPage: 10,
    };
  },
  computed: {},
  mounted() {
    this.loadData({
      type: this.$route.params.tab ? this.$route.params.tab : 0,
    });
  },

  methods: {
    async getQRData(id) {
      QrCodeServiceApi.getData({
        type: "transactions",
        id: id,
      })
        .then((response) => {
          if (response.data.result) {
            this.qrData = response.data.result;
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    transactionCaller(data) {
      TransactionServiceApi.getAllTransaction(data)
        .then((response) => {
          if (response.data.success) {
            this.orderData = response.data.address.data;
            this.rows = response.data.address.total;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async loadData(val) {
      this.loading = true;
      this.orderData = [];

      let data = null;
      switch (parseInt(val.type)) {
        case 0:
          data = {
            progress: 0,
            paginate: this.order.pendingShipment.perPage,
            page: this.order.pendingShipment.page,
            search: val.search || null,
          };
          break;
        case 1:
          data = {
            progress: 1,
            paginate: this.order.enRouteToSheesh.perPage,
            page: this.order.enRouteToSheesh.page,
            search: val.search || null,
          };
          break;
        case 2:
          data = {
            progress: 2,
            paginate: this.order.AuthProcess.perPage,
            page: this.order.AuthProcess.page,
            search: val.search || null,
          };

          break;
        case 3:
          data = {
            progress: 3,
            paginate: this.order.enRouteToBuyers.perPage,
            page: this.order.enRouteToBuyers.page,
            search: val.search || null,
          };

          break;
        case 4:
          data = {
            progress: 4,
            paginate: this.order.receivedByBuyer.perPage,
            page: this.order.receivedByBuyer.page,
            search: val.search || null,
          };
          break;
        case 5:
          data = {
            progress: 5,
            paginate: this.order.itemRejected.perPage,
            page: this.order.itemRejected.page,
            search: val.search || null,
          };

          break;
        case 6:
          data = {
            progress: 6,
            paginate: this.order.itemCancelled.perPage,
            page: this.order.itemCancelled.page,
            search: val.search || null,
          };
          break;
        case 7:
          data = {
            progress: 7,
            paginate: this.order.paymentFailed.perPage,
            page: this.order.paymentFailed.page,
            search: val.search || null,
          };

          break;

        default:
          break;
      }
      if (data) this.transactionCaller(data);
    },
    currentPageChange(val) {
      switch (parseInt(val.type)) {
        case 0:
          this.order.pendingShipment.page = val.page;
          break;
        case 1:
          this.order.enRouteToSheesh.page = val.page;
          break;
        case 2:
          this.order.AuthProcess.page = val.page;
          break;
        case 3:
          this.order.enRouteToBuyers.page = val.page;
          break;
        case 4:
          this.order.receivedByBuyer.page = val.page;
          break;
        case 5:
          this.order.itemRejected.page = val.page;
          break;
        case 6:
          this.order.itemCancelled.page = val.page;
          break;
        case 7:
          this.order.paymentFailed.page = val.page;
          break;

        default:
          break;
      }
      this.loadData({
        type: val.type,
      });
    },
    tabChanged(val) {
      this.loadData({
        type: val,
      });
    },
    perPageChange(val) {
      switch (parseInt(val.type)) {
        case 0:
          this.order.pendingShipment.perPage = val.paginate;
          this.order.pendingShipment.page = 1;
          break;
        case 1:
          this.order.enRouteToSheesh.perPage = val.paginate;
          this.order.enRouteToSheesh.page = 1;
          break;
        case 2:
          this.order.AuthProcess.perPage = val.paginate;
          this.order.AuthProcess.page = 1;
          break;
        case 3:
          this.order.enRouteToBuyers.perPage = val.paginate;
          this.order.enRouteToBuyers.page = 1;
          break;
        case 4:
          this.order.receivedByBuyer.perPage = val.paginate;
          this.order.receivedByBuyer.page = 1;
          break;
        case 5:
          this.order.itemRejected.perPage = val.paginate;
          this.order.itemRejected.page = 1;
          break;
        case 6:
          this.order.itemCancelled.perPage = val.paginate;
          this.order.itemCancelled.page = 1;
          break;
        case 7:
          this.order.paymentFailed.perPage = val.paginate;
          this.order.paymentFailed.page = 1;
          break;
        default:
          break;
      }
      this.loadData({
        type: val.type,
      });
    },
    searchTerm(val) {
      this.resetOrder(val);
      this.loadData({
        type: val.type,
        search: val.term,
      });
    },
    resetOrder(val) {
      switch (parseInt(val.type)) {
        case 0:
          this.order.pendingShipment.page = 1;
          break;
        case 1:
          this.order.enRouteToSheesh.page = 1;
          break;
        case 2:
          this.order.AuthProcess.page = 1;
          break;
        case 3:
          this.order.enRouteToBuyers.page = 1;
          break;
        case 4:
          this.order.receivedByBuyer.page = 1;
          break;
        case 5:
          this.order.itemRejected.page = 1;
          break;
        case 6:
          this.order.itemCancelled.page = 1;
          break;
        case 7:
          this.order.paymentFailed.page = 1;
          break;
        default:
          break;
      }
    },
    orderSelected(order) {
      this.transactionSelect = order.order;
    },
    modalClosed(val) {
      if (val) {
        this.transactionSelect = null;
      }
    },
    reloadOrder(val) {
      this.resetOrder(val);
      this.reloadData(val.type);
    },
    reloadData(val) {
      this.loadData({
        type: val,
      });
    },
    generateQR(item) {
      this.qrGenerating = true;
      this.qrData = null;
      this.showQR = true;

      this.getQRData(item.item.item.id);
    },

    resetQR(value) {
      this.showQR = !value;
      this.qrGenerating = false;
    },
  },
  watch: {},
};
</script>

<template>
  <Layout>
    <div style="position: relative">
      <div class="spinner-pos">
        <Spinner :show="loading" />
      </div>
    </div>

    <b-tabs @activate-tab="tabChanged" v-model="step" class="orderTabs">
      <b-tab title="Pending shipment">
        <div class="bg-white">
          <Orders
            :rows="rows"
            :perPage="order.pendingShipment.perPage"
            :page="order.pendingShipment.page"
            :data="orderData"
            type="0"
            v-on:currentPage="currentPageChange"
            v-on:searchTerm="searchTerm"
            v-on:orderSelected="orderSelected"
            v-on:reloadOrder="reloadOrder"
          />
        </div>
      </b-tab>
      <b-tab title="En-route to sheesh">
        <div class="bg-white">
          <Orders
            :rows="rows"
            :perPage="order.enRouteToSheesh.perPage"
            :page="order.enRouteToSheesh.page"
            type="1"
            :data="orderData"
            v-on:currentPage="currentPageChange"
            v-on:perPageChange="perPageChange"
            v-on:searchTerm="searchTerm"
            v-on:orderSelected="orderSelected"
            v-on:reloadOrder="reloadOrder"
          />
        </div>
      </b-tab>
      <b-tab title="Received& Auth Process">
        <div class="bg-white">
          <Orders
            :rows="rows"
            :perPage="order.AuthProcess.perPage"
            :page="order.AuthProcess.page"
            :data="orderData"
            type="2"
            v-on:currentPage="currentPageChange"
            v-on:perPageChange="perPageChange"
            v-on:searchTerm="searchTerm"
            v-on:orderSelected="orderSelected"
            v-on:reloadOrder="reloadOrder"
            v-on:generateQR="generateQR"
          /></div
      ></b-tab>
      <b-tab title="En-route to buyer">
        <div class="bg-white">
          <Orders
            :rows="rows"
            :perPage="order.enRouteToBuyers.perPage"
            :page="order.enRouteToBuyers.page"
            v-on:currentPage="currentPageChange"
            :data="orderData"
            type="3"
            v-on:perPageChange="perPageChange"
            v-on:searchTerm="searchTerm"
            v-on:orderSelected="orderSelected"
            v-on:reloadOrder="reloadOrder"
            v-on:generateQR="generateQR"
          /></div
      ></b-tab>
      <b-tab title="Received by buyer"
        ><div class="bg-white">
          <Orders
            :rows="rows"
            :perPage="order.receivedByBuyer.perPage"
            :page="order.receivedByBuyer.page"
            v-on:currentPage="currentPageChange"
            :data="orderData"
            type="4"
            v-on:perPageChange="perPageChange"
            v-on:searchTerm="searchTerm"
            v-on:orderSelected="orderSelected"
            v-on:reloadOrder="reloadOrder"
            v-on:generateQR="generateQR"
          />
        </div>
      </b-tab>
      <b-tab title="Item Rejected"
        ><div class="bg-white">
          <Orders
            :rows="rows"
            :perPage="order.itemRejected.perPage"
            :page="order.itemRejected.page"
            v-on:currentPage="currentPageChange"
            :data="orderData"
            type="5"
            v-on:perPageChange="perPageChange"
            v-on:searchTerm="searchTerm"
            v-on:orderSelected="orderSelected"
            v-on:reloadOrder="reloadOrder"
          />
        </div>
      </b-tab>
      <b-tab title="Transaction Cancelled"
        ><div class="bg-white">
          <Orders
            :rows="rows"
            :perPage="order.itemCancelled.perPage"
            :page="order.itemCancelled.page"
            v-on:currentPage="currentPageChange"
            :data="orderData"
            type="6"
            v-on:perPageChange="perPageChange"
            v-on:searchTerm="searchTerm"
            v-on:orderSelected="orderSelected"
            v-on:reloadOrder="reloadOrder"
          />
        </div>
      </b-tab>
      <b-tab title="Payment Failed"
        ><div class="bg-white">
          <Orders
            :rows="rows"
            :perPage="order.paymentFailed.perPage"
            :page="order.paymentFailed.page"
            v-on:currentPage="currentPageChange"
            :data="orderData"
            type="7"
            v-on:perPageChange="perPageChange"
            v-on:searchTerm="searchTerm"
            v-on:orderSelected="orderSelected"
            v-on:reloadOrder="reloadOrder"
          />
        </div>
      </b-tab>
    </b-tabs>
    <ChangeProgress
      v-on:modalClosed="modalClosed"
      :transactionData="transactionSelect"
      v-on:orderChange="reloadData"
    />
    <QRCode
      :showQR="showQR"
      :qrData="qrData"
      v-on:resetQR="resetQR"
      :qrGenerating="qrGenerating"
    />
  </Layout>
</template>
