
import axios from "@/plugins/axios";
import authHeader from '../auth-header'
import ConfigService from "../config";

const API_URL = ConfigService.base_url;

class InvoiceServiceApi {
  getInvoiceDetails(data) {
    return axios
      .get(API_URL + "admin/invoice", {
        params:data,
        headers: authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }


}

export default new InvoiceServiceApi();
