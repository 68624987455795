<script>
import Progress from "@/service/transactionType";
import UserServiceApi from "@/service/api/users";
import Invoice from "@/components/invoice";

export default {
  props: ["transactionData"],
  components: { Invoice },
  data() {
    return {
      progress: Progress,
      loadingSellerAddress: false,
      loadingBuyerAddress: false,
      progressSelected: null,
      addressSeller: null,
      addressBuyer: null,
      updating: false,
      openInvoice: false,
      userType: null,
      type: "transaction",
    };
  },
  computed: {
    progressAvailable() {
      if (this.transactionData) {
        var labelData = [];
        for (const [key, value] of Object.entries(
          this.progress[this.transactionData.type]
        )) {
          if (key !== "0") {
            labelData.push({
              text: value.label,
              value: key,
            });
          }
        }
        return labelData;
      } else {
        return [];
      }
    },
  },
  mounted() {},
  methods: {
    resetTransactionData() {
      this.$emit("modalClosed", true);
      this.loadingSellerAddress = false;
      this.loadingBuyerAddress = false;
      this.progressSelected = null;
      this.addressSeller = null;
      this.addressBuyer = null;
    },
    getProgress(type, id) {
      const label = this.progress[type][id].label;
      const status = this.progress[type][id].status;
      const color = this.progress[type][id].color;
      const progress = `${label}<br/>
      <div style="background-color:${color};font-size:12px;color:white;display:inline-block;padding:2px 5px;border-radius:5px;">${status}</div>`;
      return progress;
    },
    buyerAddress(id) {
      this.loadingBuyerAddress = true;
      this.addressBuyer = this.loadAddress(id, "buyer").then(() => {
        this.loadingBuyerAddress = false;
      });
    },
    sellerAdress(id) {
      this.loadingSellerAddress = true;
      this.addressSeller = this.loadAddress(id, "seller").then(() => {
        this.loadingSellerAddress = false;
      });
    },
    async loadAddress(id, type) {
      await UserServiceApi.getUserAddress(id).then((response) => {
        if (response.data) {
          if (type === "seller") {
            if (response.data.success) {
              this.addressSeller = response.data.address;
            } else {
              this.addressSeller = null;
            }
          } else {
            if (response.data.success) {
              this.addressBuyer = response.data.address;
            } else {
              this.addressBuyer = null;
            }
          }
        }
      });
    },

    getProgressDetails(type, id) {
      return this.progress[type][id].label;
    },
  },
  watch: {
    transactionData() {
      if (this.transactionData) {
        this.$refs["viewTransactionDetails"].show();
        this.progressSelected = this.transactionData.progress;
      }
    },
  },
};
</script>

<template>
  <div>
    <b-modal
      ref="viewTransactionDetails"
      id="view-transaction-details"
      centered
      size="lg"
      @hidden="resetTransactionData"
    >
      <template #modal-header>
        <div v-if="transactionData" style="margin: auto; font-size: 16px">
          <i
            style="margin: 10px; font-size: 16px"
            class="ri-line-chart-fill"
          ></i>
          <span>
            {{
              transactionData.type[0].toUpperCase() +
              transactionData.type.slice(1)
            }}
            details
          </span>
        </div>
      </template>
      <div v-if="transactionData">
        <div class="table-responsive">
          <table class="table table-nowrap mb-0 no-border-top">
            <tbody>
              <tr>
                <th scope="row" style="width: 400px">Product</th>
                <td>
                  <img
                    :src="transactionData.size.product.image"
                    alt=""
                    class="img-fluid"
                    srcset=""
                    width="200"
                  />
                  <div>
                    {{ transactionData.size.product.name }} -
                    {{ transactionData.size.size }}
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px">Status</th>
                <td>
                  <b-badge
                    variant="success"
                    class="ml-1"
                    v-if="transactionData.status === 'active'"
                    >{{ transactionData.status }}</b-badge
                  >
                  <b-badge variant="danger" class="ml-1" v-else>{{
                    transactionData.status
                  }}</b-badge>
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px">Updated at</th>
                <td>
                  {{
                    moment(transactionData.updated_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )
                  }}
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px">Created at</th>
                <td>
                  {{
                    moment(transactionData.created_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )
                  }}
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px">Progress</th>
                <td>
                  <div
                    v-html="
                      getProgress(
                        transactionData.type,
                        transactionData.progress
                      )
                    "
                  ></div>
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px">Orginal Price</th>
                <td>
                  {{ transactionData.original_price }}
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px">Seller Payout</th>
                <td>
                  {{ transactionData.amount_due }}
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px">Transaction Fee</th>
                <td>
                  {{ transactionData.transaction_fee }}
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px">Processing Fee</th>
                <td>
                  {{ transactionData.processing_fee }}
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px">Shipping Fee</th>
                <td>
                  {{ transactionData.shipping_fee }}
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px">Wallet Used</th>
                <td>
                <i class="fas fa-check-circle" style="color:green" v-if="transactionData.wallet_used"></i>
                <i class="fas fa-times-circle" style="color:red" v-else></i>
                </td>
              </tr>
              <tr v-if="parseFloat(transactionData.import_fee)>0">
                <th scope="row" style="width: 400px">Import Fee</th>
                <td>
                  {{ transactionData.import_fee }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="transactionData.type === 'buy'">
          <div class="row" style="overflow: hidden">
            <div class="col-lg-5">
              <div class="card address-card" style="border-radius: 10px">
                <div class="card-body">
                  <div class="mb-2 h5">Seller</div>
                  <div class="row">
                    <div class="col-3">Name</div>
                    <div class="col-9">
                      {{ transactionData.seller.user.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">Email</div>
                    <div class="col-9">
                      {{ transactionData.seller.user.email }}
                    </div>
                  </div>
                  <div class="m-2">
                    <a
                      class="primary"
                      @click="sellerAdress(transactionData.seller.address_id)"
                      >Address</a
                    >

                    <b-spinner
                      v-if="this.loadingSellerAddress"
                      small
                      variant="dark"
                      role="status"
                    ></b-spinner>
                    <div v-if="addressSeller">
                      <div class="row">
                        <div class="col-12">
                          House No : {{ addressSeller.house_number }}
                          {{ addressSeller.address }}<br />
                          {{ addressSeller.city }}<br />
                          {{ addressSeller.country }} <br />
                          {{ addressSeller.phone_number }}<br />
                          Country Code : {{ addressSeller.country_code }} <br />
                          Shiping Fee : {{ addressSeller.shipping_fee }} <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-2"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div style="font-size: 45px">
                <i class="ri-arrow-right-circle-fill"> </i>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="card address-card" style="border-radius: 10px">
                <div class="card-body">
                  <div class="mb-2 h5">Buyer</div>
                  <div class="row">
                    <div class="col-4">Name</div>
                    <div class="col-8">
                      {{ transactionData.user.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">Email</div>
                    <div class="col-8">
                      {{ transactionData.user.email }}
                    </div>
                  </div>
                  <div class="m-2">
                    <a
                      class="primary"
                      @click="buyerAddress(transactionData.address_id)"
                    >
                      Address</a
                    >
                    <b-spinner
                      v-if="this.loadingBuyerAddress"
                      small
                      variant="dark"
                      role="status"
                    ></b-spinner>

                    <div v-if="addressBuyer">
                      <div class="row">
                        <div class="col-12">
                          House No : {{ addressBuyer.house_number }}
                          {{ addressBuyer.address }}<br />
                          {{ addressBuyer.city }}<br />
                          {{ addressBuyer.country }} <br />
                          {{ addressBuyer.phone_number }}<br />
                          Country Code : {{ addressBuyer.country_code }} <br />
                          Shiping Fee : {{ addressBuyer.shipping_fee }} <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row" style="overflow: hidden">
            <div class="col-lg-5">
              <div class="card address-card" style="border-radius: 10px">
                <div class="card-body">
                  <div class="mb-2 h5">Seller</div>
                  <div class="row">
                    <div class="col-4">Name</div>
                    <div class="col-8">
                      {{ transactionData.user.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">Email</div>
                    <div class="col-8">
                      {{ transactionData.user.email }}
                    </div>
                  </div>
                  <div>
                    <a
                      class="primary"
                      @click="
                        openInvoice = true;
                        userType = 'seller';
                      "
                      >Invoice</a
                    >
                  </div>
                  <div class="m-2">
                    <a
                      class="primary"
                      @click="buyerAddress(transactionData.address_id)"
                    >
                      Address</a
                    >
                    <b-spinner
                      v-if="this.loadingBuyerAddress"
                      small
                      size="1px"
                      variant="dark"
                      role="status"
                    ></b-spinner>

                    <div v-if="addressBuyer">
                      <div class="row">
                        <div class="col-12">
                          House No : {{ addressBuyer.house_number }}
                          {{ addressBuyer.address }}<br />
                          {{ addressBuyer.city }}<br />
                          {{ addressBuyer.country }} <br />
                          {{ addressBuyer.phone_number }}<br />
                          Country Code : {{ addressBuyer.country_code }} <br />
                          Shiping Fee : {{ addressBuyer.shipping_fee }} <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-2"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div style="font-size: 45px">
                <i class="ri-arrow-right-circle-fill"> </i>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="card address-card" style="border-radius: 10px">
                <div class="card-body">
                  <div class="mb-2 h5">Buyer</div>
                  <div class="row">
                    <div class="col-4">Name</div>
                    <div class="col-8">
                      {{ transactionData.buyer.user.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">Email</div>
                    <div class="col-8">
                      {{ transactionData.buyer.user.email }}
                    </div>
                  </div>
                  <div>
                    <a
                      class="primary"
                      @click="
                        openInvoice = true;
                        userType = 'buyer';
                      "
                      >Invoice</a
                    >
                  </div>
                  <div class="m-2">
                    <a
                      class="primary"
                      @click="sellerAdress(transactionData.buyer.address_id)"
                      >Address</a
                    >
                    <b-spinner
                      v-if="this.loadingSellerAddress"
                      small
                      variant="dark"
                      role="status"
                    ></b-spinner>
                    <div v-if="addressSeller">
                      <div class="row">
                        <div class="col-12">
                          House No : {{ addressSeller.house_number }}
                          {{ addressSeller.address }}<br />
                          {{ addressSeller.city }}<br />
                          {{ addressSeller.country }} <br />
                          {{ addressSeller.phone_number }}<br />
                          Country Code : {{ addressSeller.country_code }} <br />
                          Shiping Fee : {{ addressSeller.shipping_fee }} <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <Invoice
      :openInvoice="openInvoice"
      :invoiceData="transactionData"
      :userType="userType"
      :type="type"
      v-on:showInvoice="openInvoice = false"
    />
  </div>
</template>

<style>
#view-transaction-details .table th,
#view-transaction-details .table td {
  border-top: none;
}
</style>
