import axios from "@/plugins/axios";
//import authHeader from '../auth-header'
import ConfigService from "../config"
import authHeader from '../auth-header'
import authHeaderMultipart from "../auth-header-multipart"

const API_URL = ConfigService.base_url;

class UserServiceApi {
    getAllUser() {
        return axios
          .get(API_URL + "users", {
            params: {
              status: "all",
            },
            headers: authHeader()
           
          },
        )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
      getUserDetails(id) {
        return axios
          .get(API_URL + "user", {
            params: {
              user_id: id,
            },
            headers: authHeader()
           
          },
        )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
      getUserAddress(id) {
        return axios
          .get(API_URL + "admin/address", {
            params: {
              id: id,
            },
            headers: authHeader()
           
          },
        )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
      balanceAction(data){
        return axios
          .post(API_URL + "admin/wallet", data,
            { headers: authHeader()})
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });

      }
      totalUsers(){
        return axios
          .get(API_URL + "admin/totalusers",
            { headers: authHeader()})
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });

      }
      updateUser(data){
        return axios
          .post(API_URL + "update/user", data,
            { headers: authHeaderMultipart()})
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });

      }
      deleteUser(data){
        return axios
          .post(API_URL + "update/user", data,
            { headers: authHeader()})
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });

      }
}

export default new UserServiceApi()