<script>
import TransactionServiceApi from "@/service/api/transaction.js";
import TradeServiceApi from "@/service/api/trade.js"

export default {
  props: ["active", "data", "status","type","user_type"],
  data() {
    return {
      authorizer: null,
      authorizing: false,
    };
  },

  methods: {
    verifyTransaction(data, status) {
      if (this.authorizer && this.authorizer.length) {
        this.authorizing = true;
        if(this.type ==="order"){
        TransactionServiceApi.verifyTransaction({
          transaction_id: data.id,
          verified_by: this.authorizer.trim(),
          verified: status === "Authentic" ? "pass" : "fail",
        })
          .then((response) => {
            if (response.data.success) {
              this.$refs["AuthenticModal"].hide();
              this.authorizer = null;
              this.actionMessage("Order is verified successfully!");
              this.$emit("itemRecieved");
            }
          })
          .catch(() => {
            this.actionMessage("Order verification is failed!", "danger");
          })
          .finally(() => {
            this.authorizing = false;
          });
        }
        else if(this.type==="trade"){
          TradeServiceApi.verifyTrade({
          trade_id: data.id,
          verified_by: this.authorizer.trim(),
          verified: status === "Authentic" ? "pass" : "fail",
          user: this.user_type
        })
          .then((response) => {
            if (response.data.success) {
              this.$refs["AuthenticModal"].hide();
              this.authorizer = null;
              this.actionMessage("Trade is verified successfully!");
            }
          })
          .catch(() => {
            this.actionMessage("Trade verification is failed!", "danger");
          })
          .finally(() => {
            this.authorizing = false;
          });
        }
      }
    },
    actionMessage(body, variant = "success") {
      this.$bvToast.toast(body, {
        title: "Authentication Process",
        variant: variant,
        solid: true,
      });
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.$refs["AuthenticModal"].show();
      }
    },
  },
  computed: {},
};
</script>

<template>
  <b-modal
    ref="AuthenticModal"
    id="AuthenticModal"
    :title="`Authentication Status (${this.status} )`"
    @hidden="
      () => {
        this.$emit('reset-auth-modal', true);
      }
    "
    hide-footer
  >
    <div v-if="data">
    <div v-if="type ==='order'">
      <div style="text-align:center">
        <div class="ml-5 mr-5">
          <img
            v-if="data.size.product.image"
            :src="data.size.product.image"
            alt=""
            srcset=""
            class="img-fluid"
          />
        </div>
        <div>
          <span class="font-weight-bold"> Size : </span> {{ data.size.size }}
        </div>
        <div>
          <span class="font-weight-bold"> Quantity :</span>
          {{ data.size.quantity }}
        </div>
      </div>
      <p class="my-4 p-2">
        Are you sure, the ORDER-{{ data.id }} with the product
        <em>{{ data.size.product.name }}</em> from the seller
        <span v-if="data.seller">{{ data.seller.user.name }}</span>
        <span v-else>{{ data.user.name }}</span>
        for the buyer
        <span v-if="data.buyer">{{ data.buyer.user.name }}</span>
        <span v-else>{{ data.user.name }}</span> is {{ status }}?
      </p>
      </div>
      <FormulateInput
        type="text"
        label="Sign Here"
        placeholder="Authorizer name is here!"
        v-model="authorizer"
        validation="required"
        error-behavior="live"
      />

      <div>
        <button
          class="btn btn-secondary"
          @click="
            () => {
              this.$refs['AuthenticModal'].hide();
            }
          "
        >
          Cancel
        </button>
        <button
          class="btn btn-danger float-right"
          @click="verifyTransaction(data, status)"
        >
          <span v-if="!authorizing">Confirm</span>
          <span v-else>Confirming...</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<style></style>
