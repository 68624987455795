import axios from "@/plugins/axios";
// import authHeader from '../auth-header'
import ConfigService from "../config";
import authHeader from "../auth-header"
const API_URL = ConfigService.base_url;

class TransactionServiceApi {
  updateTransaction(data) {
    return axios
      .post(API_URL + "update/transaction",
        this.convertToFormData(data),
        {
          headers: authHeader()
        })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  cancelTransaction(data) {
    return axios
      .post(API_URL + "cancel/transaction",
        this.convertToFormData(data),
        {
          headers: authHeader()
        })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  convertToFormData(item) {
    var form_data = new FormData();
    for (var key in item) {
      form_data.append(key, item[key]);
    }
    return form_data;
  }
  getAllTransaction(args) {
    let params = {};
    if (args.search) {
      params.search = args.search;

    }

    params.progress = args.progress;
    params.paginate = args.paginate;
    params.page = args.page;
    return axios
      .get(API_URL + "admin/transactions",
        {
          params: params,
          headers: authHeader()
        })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  verifyTransaction(data) {
    return axios
      .post(API_URL + "admin/verifytransaction",
        data,
        {
          headers: authHeader()
        })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  transactionReadStatus(data) {
    return axios
      .post(API_URL + "transaction/delivertobuyer",data,
        {
          headers: authHeader()
        })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });

  }
}



export default new TransactionServiceApi();
