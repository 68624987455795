<script>
// import Progress from "@/service/transactionType";
import ProductReceiveModal from "./product-receive-modal.vue";
import AuthModal from "./auth-modal.vue";
import TransactionServiceApi from "@/service/api/transaction";

export default {
  props: ["data", "rows", "perPage", "type", "page"],
  components: { ProductReceiveModal, AuthModal },
  data() {
    return {
      fields: [
        { key: "id", sortable: false },
        { key: "product", sortable: false },
        { key: "user", sortable: false, label: "Created By" },
        { key: "type", sortable: false },
        { key: "second_party", sortable: false, label: "Second Party" },
        { key: "amount_due", sortable: false, label: "Seller Payout" },

        { key: "created_at", sortable: false },
        { key: "updated_at", sortable: false },
        { key: "action", sortable: false, onlyForActionBySheesh: true },
      ],
      currentPage: this.page,
      searchTerm: null,
      perPages: this.perPage,
      pageOptions: [10, 25, 50, 100],
      ProductReceiveModalIsActive: false,
      ProductReceiveModalItem: null,

      ProductAuthModalIsActive: false,
      ProductAuthModalItem: null,
      ProductAuthModalStatus: null,
      changingProgress: false,
      currentItem: null,
      openConfirmation: true,
    };
  },

  methods: {
    getProgress(type, id) {
      const label = this.progress[type][id].label;
      const status = this.progress[type][id].status;
      const color = this.progress[type][id].color;
      const progress = `${label}<br/>
      <div style="background-color:${color};font-size:12px;color:white;display:inline-block;padding:2px 5px;border-radius:5px;">${status}</div>`;
      return progress;
    },
    pageChanged(val) {
      this.$emit("currentPage", { page: val, type: this.type });
      this.currentPage = val;
    },
    searchByEmail() {
      this.currentPage = 1;
      this.$emit("searchTerm", {
        term: this.searchTerm,
        type: this.type,
      });
    },
    orderSelected(val) {
      this.$emit("orderSelected", {
        order: val,
        type: this.type,
      });
    },
    reloadOrder() {
      this.$emit("reloadOrder", {
        type: this.type,
      });
    },
    generateQR(row) {
      this.$emit("generateQR", {
        item: row,
      });
    },
    downloadQR(row) {
      this.$emit("downloadQR", {
        item: row,
      });
    },
    itemReceived(row) {
      this.ProductReceiveModalIsActive = true;
      this.ProductReceiveModalItem = row.item;
    },
    authModalOpen(row, status) {
      this.ProductAuthModalIsActive = true;
      this.ProductAuthModalItem = row.item;
      this.ProductAuthModalStatus = status;
    },
    changeProgress(item) {
      this.$swal({
        title: "Are you sure?",
        text: "Do you want to change the progress",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          this.changingProgress = true;
          this.currentItem = item.id;
          const data = {
            transaction_id: item.id,
            progress: parseInt(item.progress) + 1,
            action: "update",
          };
          TransactionServiceApi.updateTransaction(data)
            .then(() => {
              this.ToastMessage({
                title: "Progress Update",
                body: "Progress updated succesfully!",
              });
              this.reloadOrder();
            })
            .catch(() => {
              this.ToastMessage(
                { title: "Progress Update", body: "Progress updation failed!" },
                "danger"
              );
            })
            .then(() => {
              this.changingProgress = false;
            });
        }
      });
    },
    changetransactionReadStatus(item) {
      this.$swal({
        title: "Are you sure?",
        text: "Do you want to change the progress",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          TransactionServiceApi.transactionReadStatus({
            transaction_id: item.id,
          })
            .then(() => {
              this.ToastMessage({
                title: "Progress Update",
                body: "Order has been delivered by SwftBox!",
              });
              this.reloadOrder();
            })
            .catch(() => {
              this.ToastMessage(
                { title: "Progress Update", body: "Progress updation failed!" },
                "danger"
              );
            });
        }
      });
    },

  },
  watch: {
    perPages(val) {
      this.$emit("perPageChange", {
        paginate: val,
        type: this.type,
      });
    },
  },
  computed: {
    computedFields() {
      switch (this.type) {
        case "0":
          return this.fields;
        case "1":
          return this.fields;
        case "2":
          return this.fields;
        case "3":
          return this.fields;
        case "4":
          return this.fields;
        default:
          return this.fields.filter((field) => !field.onlyForActionBySheesh);
      }
    },
  },
};
</script>

<template>
  <div>
    <ProductReceiveModal
      :active="ProductReceiveModalIsActive"
      :data="ProductReceiveModalItem"
      v-on:itemRecieved="reloadOrder"
      v-on:reset-recieve-modal="
        (val) => {
          if (val) {
            this.ProductReceiveModalIsActive = false;
            this.ProductReceiveModalItem = null;
          }
        }
      "
    />
    <AuthModal
      :active="ProductAuthModalIsActive"
      :data="ProductAuthModalItem"
      :status="ProductAuthModalStatus"
      type="order"
      v-on:itemRecieved="reloadOrder"
      v-on:reset-auth-modal="
        (val) => {
          if (val) {
            this.ProductAuthModalIsActive = false;
            this.ProductAuthModalItem = null;
            this.ProductAuthModalStatus = null;
          }
        }
      "
    />
    <div class="row p-3">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select
              v-model="perPages"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            <button @click="reloadOrder" class="btn btn-sm btn-secondary">
              <i class="ri-refresh-line"></i>
            </button>
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              type="search"
              placeholder="Search"
              class="form-control form-control-sm ml-2"
            ></b-form-input>
            <button class="btn-primary ml-1" @click="searchByEmail">
              <i class="ri-search-line"></i>
            </button>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <div class="table-responsive mb-0" v-if="data">
      <b-table
        :fields="computedFields"
        :items="data"
        responsive="sm"
        @row-clicked="orderSelected"
        selectable
      >
        <template v-slot:cell(product)="row">
          <div>
            {{ row.item.size.product.name }}
          </div>
          <div>Size:{{ row.item.size.size }}</div>
        </template>
        <template v-slot:cell(user)="row">
          <div>{{ row.value.name }}</div>
        </template>
        <template v-slot:cell(created_at)="row">
          <div>
            {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
          </div>
        </template>
        <template v-slot:cell(updated_at)="row">
          <div>
            {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
          </div>
        </template>
        <template v-slot:cell(QR)="row">
          <div>
            <button v-on:click="generateQR(row)" class="btn btn-secondary">
              Generate QR Code
            </button>
          </div>
        </template>
        <!-- <template v-slot:cell(progress)="row">
          <div v-html="getProgress(row.item.type, row.value)"></div>
        </template> -->

        <template v-slot:cell(second_party)="row">
          <div v-if="row.item.buyer">
            <div>
              {{ row.item.buyer.user.name }}
            </div>
            <b-badge style="background-color: red">Buyer</b-badge>
          </div>
          <div v-else>
            <div>
              {{ row.item.seller.user.name }}
            </div>
            <b-badge style="background-color: green"> Seller</b-badge>
          </div>
        </template>

        <template v-slot:cell(action)="row">
          <div v-if="type === '1'">
            <Button class="btn btn-sm btn-danger" @click="itemReceived(row)"
              >Receive</Button
            >
          </div>
          <div v-if="type === '2' || type === '3' || type === '4'">
            <button
              class="btn btn-sm btn-primary mb-1 pl-0"
              style="width: 72px"
              v-if="row.item.verified === '1'"
              @click="generateQR(row)"
            >
              QR
              <div
                style="
                  display: inline-block;
                  position: absolute;
                  margin-left: 2px;
                  margin-top: -4px;
                  font-size: 17px;
                "
              >
                <i class="ri-settings-line"></i>
              </div>
            </button>
          </div>
          <div v-if="type === '2'">
            <button
              class="btn btn-sm btn-primary mb-1 pl-0"
              style="width: 72px"
              v-if="row.item.verified === '1'"
              @click="changetransactionReadStatus(row.item)"
            >
              <span v-if="changingProgress && currentItem === row.item.id">
                En-routing to Buyer...</span
              >
              <span v-else> En-route to Buyer</span>
            </button>
            <div class="mb-1" v-if="!(row.item.verified === '1')">
              <Button
                class="btn btn-sm btn-success"
                style="width: 72px"
                @click="authModalOpen(row, 'Authentic')"
                >Authentic</Button
              >
            </div>
            <div v-if="!(row.item.verified === '1')">
              <Button
                class="btn btn-sm btn-danger"
                style="width: 72px"
                @click="authModalOpen(row, 'Fake')"
                >Fake</Button
              >
            </div>
          </div>
          <div v-if="type === '0'">
            <Button
              class="btn btn-sm btn-primary"
              style="width: 72px"
              @click="changeProgress(row.item)"
            >
              <span v-if="changingProgress && currentItem === row.item.id"
                >En-routing to Sheesh</span
              >
              <span v-else>En-route to Sheesh</span>
            </Button>
          </div>
          <div v-if="type === '3'">
            <Button
              class="btn btn-sm btn-primary"
              style="width: 72px"
              @click="changeProgress(row.item)"
              ><span v-if="changingProgress && currentItem === row.item.id">
                Receiving by Buyer</span
              ><span v-else> Received by Buyer </span></Button
            >
          </div>
        </template>
      </b-table>
    </div>
    <div class="no-message" v-if="data && !data.length">No Orders!</div>
    <div class="row" v-else style="padding-bottom: 50px">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers">
          <ul class="pagination pagination-rounded mb-0 float-right">
            <!-- pagination -->
            <b-pagination
              v-on:change="pageChanged"
              :total-rows="rows"
              v-model="currentPage"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.no-message {
  text-align: center;
  font-size: 18px;
  padding: 100px 0;
}
</style>
